import { DashboardProducts } from "./DashboardProducts";

export class DashboardData {

  constructor(attributes) {
    this.totalGroups = attributes.groups;
    this.totalAccounts = attributes.accounts;
    this.totalUsers = attributes.users;
    this.totalOrders = attributes.orders;
    this.productsData = new DashboardProducts({});
    if (attributes.products) {
      this.productsData = new DashboardProducts(attributes.products);
    }
  }

  totalGroups: number;
  totalAccounts: number;
  totalUsers: number;
  totalOrders: number;
  productsData: DashboardProducts;

}