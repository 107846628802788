export class DashboardProducts {

  constructor(data) {
    this.totalLentes = data.lente;
    this.totalArmacoes = data.armacao;
    this.totalAcessorios = data.acessorio;
  }

  totalLentes: number;
  totalArmacoes: number;
  totalAcessorios: number;

}