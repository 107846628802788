import axios2 from '@/axios2';
import { DashboardData } from '@/code/models/dashboard/DashboardData';
import { SalesDashboard } from '@/code/models/dashboard/SalesDashboard';

export class DashboardService {

  constructor() {}

  loadDashboard() : Promise<DashboardData> {
    return new Promise((resolve, reject) => {
      axios2.get('dashboards/v1')
      .then(
        (response: any) => {
          let data = new DashboardData({});
          if (response.data && response.data.data) {
            data = new DashboardData(response.data.data);
          }

          resolve(data);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  loadSalesDashboard(): Promise<any> {
    //return new Promise((resolve, reject) => {
      return axios2.get('dashboards/v1/mdopay')
      .then(
        (response: any) => {
          return response.data.data;
        }
      );
    //})
  }

}