<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <h1>Dashboard de pedidos</h1>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-card height="300">
          <v-card-title>
            Vendas por dia
          </v-card-title>
          <v-card-text>
            <canvas id="bar-chart" height="250"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-card height="300">
          <v-card-title>
            Total por dia
          </v-card-title>
          <v-card-text>
            <canvas id="total-dia" height="250"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card height="300">
          <v-card-title>
            Total X MDO Pay
          </v-card-title>
          <v-card-text>
            <canvas id="total-x-mdopay" height="250"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-card height="300">
          <v-card-title>
            Total por forma de pagamento
          </v-card-title>
          <v-card-text>
            <canvas id="forma-pagamento" height="250"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-card height="300">
          <v-card-title>
            Top lojas do dia
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersTopLojas"
              :items="topLojasDia"
              disable-filtering
              hide-default-header
              hide-default-footer
              class="elevation-1 mt-4"
              item-key="id"
              :loading="loadingDashboard"
              loading-text="Carregando..."
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card height="300">
          <v-card-title>
            Top lojas da semana
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersTopLojas"
              :items="topLojasSemana"
              disable-filtering
              hide-default-header
              hide-default-footer
              class="elevation-1 mt-4"
              item-key="id"
              :loading="loadingDashboard"
              loading-text="Carregando..."
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Bar from '@/components/charts/Bar.vue';
import { Chart, registerables } from 'chart.js';
import Vue from 'vue';
import { DashboardService } from '@/code/services/dashboard/dashboardService';

export default {

  data() {
    return {
      loadingDashboard: false,
      topLojasSemana: [],
      topLojasDia: [],
      totalSalesByDate: {
        type: "line",
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      totalByDate: {
        type: "bar",
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      totalxMdoPay: {
        type: "bar",
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      totalFormaPagamento: {
        type: "pie",
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      headersTopLojas: [
        {
          text: 'Id',
          value: 'id',
          filterable: false
        },
        {
          text: 'Nome',
          value: 'nome',
          filterable: false
        },
        {
          text: 'Qtde',
          value: 'quantidade',
          filterable: false
        },
        {
          text: 'Valor',
          value: 'valor',
          filterable: false
        },
      ]
    }
  },

  mounted() {
    Chart.register(...registerables);
    // const data = this.totalSalesByDate;
    // const ctx = document.getElementById('bar-chart');
    // new Chart(ctx, data);
    
    this.loadData();
  },

  methods: {
    loadData: async function() {
      this.loadingDashboard = true;
      const service = new DashboardService();
      const data = await service.loadSalesDashboard();

      this.totalSalesByDate.data = {
        labels: data.totalSalesByDate.map(value => {
          return value.label;
        }),
        datasets: [
          {
            label: "Vendas",
            data: data.totalSalesByDate.map(value => {
              return value.value;
            }),
            backgroundColor: "#36495d",
            borderColor: "#36495d",
            borderWidth: 3
          }
        ]
      };
      const chartSalesByDate = document.getElementById('bar-chart');
      new Chart(chartSalesByDate, this.totalSalesByDate);

      this.totalByDate.data = {
        labels: data.totalPorData.map(value => {
          return value.label;
        }),
        datasets: [
          {
            label: "Vendas",
            data: data.totalPorData.map(value => {
              return value.value;
            }),
            backgroundColor: "#36495d",
            borderColor: "#36495d",
            borderWidth: 3
          }
        ]
      };
      const chartTotalByDate = document.getElementById('total-dia');
      new Chart(chartTotalByDate, this.totalByDate);

      this.totalxMdoPay.data = {
        labels: data.totalxMdoPay.map(value => {
          return value.label;
        }),
        datasets: [
          {
            label: "Total",
            data: data.totalxMdoPay.map(value => {
              return value.total;
            }),
            backgroundColor: "#365d5d",
            borderColor: "#365d5d",
            borderWidth: 3
          },
          {
            label: "MDO Pay",
            data: data.totalxMdoPay.map(value => {
              return value.MDOPay;
            }),
            backgroundColor: "#37365d",
            borderColor: "#37365d",
            borderWidth: 3
          }
        ]
      };
      const chartTotalXMDOPay = document.getElementById('total-x-mdopay');
      new Chart(chartTotalXMDOPay, this.totalxMdoPay);

      this.totalFormaPagamento.data = {
        labels: data.valoresPorTipo.map(value => {
          return value.label;
        }),
        datasets: [
          {
            label: "Total",
            data: data.valoresPorTipo.map(value => {
              return value.valor;
            }),
            backgroundColor: [ "#36495d", '#37365d', '#365d5d' ],
            borderWidth: 3
          }
        ]
      };
      const chartTotalFormaPagamento = document.getElementById('forma-pagamento');
      new Chart(chartTotalFormaPagamento, this.totalFormaPagamento);

      this.topLojasDia = data.top5LojasDia;
      this.topLojasSemana = data.top5LojasSemana;

      this.loadingDashboard = false;
    }
  },

  components: {
    Bar
  }

}
</script>